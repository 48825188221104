import { createSlice } from "@reduxjs/toolkit";

const ui = createSlice({
  name: "ui",
  initialState: {
    mode: null,
    timer: {
      timeLeft: 60,
      timerActive: false
    },
    navbar: {
      isOpen: false,
    }
  },
  reducers: {
    hideLanding(state) {
      state.displayLanding = false
    },
    setGameMode(state, {payload}){
      localStorage.setItem('mode', payload)
      state.mode = payload
    },
    startTimer(state){
      state.timer.timerActive = true
    },
    stopTimer(state){
      state.timer.timerActive = false
      state.timer.timeLeft = 60
    },
    updateTimeLeft(state, {payload}){
      state.timer.timeLeft = payload
    },
    toggleDrawer(state, { payload }) {
      state.navbar.isOpen = payload;
    },
  },
});

const { actions, reducer } = ui;

export const {
  hideLanding,
  setGameMode,
  updateTimeLeft,
  startTimer,
  stopTimer,
  toggleDrawer
} = actions;

export default reducer;
