import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./RankingModal.css";

export default function RankingModal({ isOpen, openModal }) {
  return (
    <Modal
      open={isOpen}
      onClose={() => openModal(false)}
      aria-labelledby="Rules"
    >
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon
            onClick={() => openModal(false)}
            className="close-button"
          />
          <div className="modal-content-container ranking-container flex-column">
            <h3>Rankings</h3>
            <p>Ranks are based on the total number of stars earned.</p>
            <p>The minimum stars to reach each rank are:</p>
            <ul>
              <li><strong>Beginner</strong> (0)</li>
              <li><strong>Moving Up</strong> (3)</li>
              <li><strong>Solid</strong> (6)</li>
              <li><strong>Nice</strong> (9)</li>
              <li><strong>Great</strong> (12)</li>
              <li><strong>Amazing</strong> (14)</li>
              <li><strong>Genius</strong> (15)</li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
}
