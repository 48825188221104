import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./RulesModal.css";
import gif from "../../images/rules.gif";
import { getStars } from "../../util";

export default function RulesModal({ isOpen, openModal }) {
  return (
    <Modal
      open={!!isOpen}
      onClose={() => {
        openModal(false);
      }}
      aria-labelledby="Rules"
    >
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon
            onClick={() => openModal(false)}
            className="close-button"
          />
          <div className="modal-content-container rules-container">
            <h3>How to play Digits</h3>
            <p>Use any combination of numbers to reach the target number.</p>
            <img src={gif} alt="animation" />
            <ul>
              <li>
                Add, subtract, multiply, or divide to get as close to the target
                number as you can.
              </li>
              <li className="super-bold">
                You don't have to use all 6 numbers.
              </li>
              <li>To start a new calculation, select a different number.</li>
              <li>
                Calculations that produce fractions or negative numbers won't be
                accepted.
              </li>
            </ul>
            <ul className="mt-0">
              <li className="super-bold">
                Earn stars when you submit an answer that is:
              </li>
              <li className="flex-row">
                <div className="flex-row">
                  {getStars(3, 18, true, "#ffd105")}
                </div>
                The target number.
              </li>
              <li className="flex-row">
                <div className="flex-row">
                  {getStars(2, 18, true, "#ffd105")}
                </div>
                1 - 10 away from the target number.
              </li>
              <li className="flex-row">
                <div className="flex-row">
                  {getStars(1, 18, true, "#ffd105")}
                </div>
                11 - 25 away from the target number.
              </li>
            </ul>
            <p className="super-bold mt-0 mb-0">
              New puzzles released daily at midnight.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
