import { useSelector } from "react-redux";
import "./Ranking.css";
import classNames from "classnames";
import star from "../../images/star.svg";
import RankingModal from "../RankingModal";
import { useState } from "react";

export default function Ranking() {
  const { game } = useSelector((state) => state.main);
  const [isOpen, openModal] = useState(false);

  const scores = Object.values(game)
    .map((el) => el.score || null)
    .filter(Boolean);
  const totalScore = scores.reduce(function (total, num) {
    return total + num;
  }, 0);

  const level = () => {
    if (totalScore < 3) return "Beginner";
    if (totalScore > 2 && totalScore < 6) return "Moving up";
    if (totalScore > 5 && totalScore < 9) return "Solid";
    if (totalScore > 8 && totalScore < 12) return "Nice";
    if (totalScore > 11 && totalScore < 14) return "Great";
    if (totalScore === 14) return "Amazing";
    if (totalScore === 15) return "Genius!";
  };

  const getWidth = () => {
    if (totalScore < 3) return 0;
    if (totalScore > 2 && totalScore < 6) return 40;
    if (totalScore > 5 && totalScore < 9) return 95;
    if (totalScore > 8 && totalScore < 12) return 150;
    if (totalScore > 11 && totalScore < 14) return 200;
    if (totalScore === 14) return 240;
    if (totalScore === 15) return 260;
  };

  const getStarPosition = () => {
    if (totalScore < 6) return 40;
    if (totalScore > 5 && totalScore < 9) return 95;
    if (totalScore > 8 && totalScore < 12) return 150;
    if (totalScore > 11 && totalScore < 14) return 200;
    if (totalScore === 14) return 240;
    if (totalScore === 15) return 265;
  };

  return (
    <>
      <div
        id="rank-bar-desktop"
        className="pointer"
        onClick={() => openModal(true)}
      >
        <span id="rank-text-desktop" className="toolbar-button">
          {level()}
        </span>
        <div id="rank-bar-container-desktop">
          <div id="rank-bar-line-desktop">
            <div
              id="rank-bar-line-desktop-completion"
              style={{ width: getWidth(), height: 3, background: "var(--primary-blue)" }}
            />
          </div>
          <div id="rank-bar-stars-desktop">
            {totalScore > 2 && (
              <span id="progress-star" style={{ left: getStarPosition() }}>
                <img className="progress-star" src={star} alt="star"></img>
                <span id="progress-star-text">{totalScore}</span>
              </span>
            )}
            <span
              className={classNames(
                totalScore > 2 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(totalScore > 2 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(
                totalScore > 2 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(totalScore > 2 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(totalScore > 2 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(
                totalScore > 5 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(totalScore > 5 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(totalScore > 5 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(
                totalScore > 8 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(totalScore > 8 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(totalScore > 8 && "", "progress-invisible")}
            ></span>
            <span
              className={classNames(
                totalScore > 11 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(
                totalScore > 11 && "",
                "progress-invisible"
              )}
            ></span>
            <span
              className={classNames(
                totalScore > 13 && "progress-filled",
                "progress-empty"
              )}
            ></span>
            <span
              className={classNames(
                totalScore === 15 && "progress-filled",
                "progress-empty"
              )}
            ></span>
          </div>
        </div>
      </div>
      <RankingModal isOpen={isOpen} openModal={openModal} />
    </>
  );
}
