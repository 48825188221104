
// import contex from '../../images/contex-logo.png';
import wordle from '../../images/wordle-logo.png';
import './navbar-drawer.scss';

export default function NavbarDrawer() {
  return (
    <div className="flex-column justify-between h-100">
      <div>
        <div className="navbar-header">
          <h4 className="f20 fw-900">Games</h4>
          <p className="bold f11 mt-20 fw-900">UP FOR MORE CHALLENGES?</p>
        </div>
        <ul className="no-style-ul mt-30">
          {/* <li>
            <a
              className="no-underline c-black flex-row"
              href={'https://playdigitsnyt.com/contex'}
            >
              <img
                style={{ width: 15 }}
                className="mr-10"
                src={contex}
                alt="decoration"
              />{' '}
              <p>Contex</p>
              <p className="most-played">Most played</p>
            </a>
          </li> */}
          <li>
            <a
              className="no-underline c-black flex-row"
              href="https://adivinalapalabradehoy.com/"
            >
              <img
                style={{ width: 15 }}
                className="mr-10"
                src={wordle}
                alt="decoration"
              />{' '}
              <p>Wordle in Spanish</p>
            </a>
          </li>
        </ul>
      </div>
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
        className="pl-30 no-underline c-black"
      >
        Privacy policy
      </a>
    </div>
  );
}
