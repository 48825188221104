function Divide() {
  return (
    <svg
      width={30}
      viewBox="0 0 90 90"
    >
      <g id="Symbol">
        <path
          className="st0"
          d="M71.9 36H18.1c-5 0-9.1 4-9.1 9s4.1 9 9.1 9H72c5 0 9.1-4 9.1-9s-4.2-9-9.2-9z"
        />
        <circle className="st0" cx="45" cy="18" r="9" />
        <circle className="st0" cx="45" cy="72" r="9" />
      </g>
    </svg>
  );
}

export default Divide;
