import { useSelector } from "react-redux";
import "./StatusBoard.css";
import { calculateSteps } from "../../util";

export function StatusBoard({ history = [] }) {
  const { currentTarget, game } =
  useSelector((state) => state.main)
  const { expression, reveal } = game[currentTarget] || {};
  const expressionElements = expression.match(/\d+|\D+/g);
  return (
    <div id="game-status-desktop">
      <div id="history">
        <div id="history-container-desktop" className="justify-between">
          <div className="flex-column history-container-content">
            <div id="history-header-desktop" className="history-header">
              Your operations
            </div>
            <ul className="history-desktop">
              {history.map((el) => (
                <li key={el.operation} className="history-entry">
                  {el.operation}
                </li>
              ))}
            </ul>
          </div>
          {reveal && (
            <div className="flex-column history-container-content solution-container">
              <div id="history-header-desktop" className="history-header">
                Our solution
              </div>
              <ul className="history-desktop">
                {calculateSteps(expression).map((el) => (
                  <li key={el} className="history-entry">
                    {el}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
