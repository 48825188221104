import { useDispatch, useSelector } from "react-redux"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RulesModal from "../RulesModal";
import { revealSolution } from "../../store/reducers/main";
import { useState } from "react";
import RevealModal from "../RevealModal";

export default function TabItems({openModal, isOpen }){
  const {currentTarget} = useSelector(state => state.main)
  const [isRevealOpen, openReveal] = useState()
  const dispatch = useDispatch()

  const reveal = () => {
    dispatch(revealSolution(currentTarget))
  }
  return(
    <>
    <div className="flex-row tab-items mb-15">
      {/* <p
        className="bold mr-10 f16 pointer"
        onClick={() => openReveal(true)}
      >
        Reveal
      </p> */}
      <HelpOutlineIcon
        className="pointer"
        onClick={() => openModal(true)}
      />
    </div>
    <RulesModal isOpen={isOpen} openModal={openModal} />
    <RevealModal isOpen={isRevealOpen} openModal={openReveal} onReveal={reveal}/>
  </>
  )
}