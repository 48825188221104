import { useEffect } from "react";
import "./App.css";
import Game from "./pages";
import { setTargets } from "./store/reducers/main";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import { setGameMode } from "./store/reducers/ui";
import DrawerComponent from "./components/Drawer";
dayjs.extend(utc)

function App() {
  const dispatch = useDispatch()
  
  useEffect(() => {
    callBackendAPI()
      .then(res => {
        const today = dayjs.utc().format("DD-MM-YYYY")
        const storedMode = localStorage.getItem('mode')
        const storedDate = localStorage.getItem('date')
        const storedGame = JSON.parse(localStorage.getItem('game'))

        if((storedDate === today) && localStorage.getItem('game')){
          dispatch(setTargets(storedGame))
          dispatch(setGameMode(storedMode))
        } else {
          localStorage.setItem('date', today)
          localStorage.removeItem('game')
          localStorage.removeItem('mode')
          return dispatch(setTargets(res))
        }
      })
      .catch(err => console.log(err));
  }, [])

  const callBackendAPI = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/express_backend`);
    const body = await response.json();

    if (response.status !== 200) {
      console.log(body.message) 
    }
    return body;
  };

  return (
    <div className="App">
      <DrawerComponent />
      <Game />
    </div>
  );
}

export default App;
