import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import "./Landing.scss";
import logo from "../../images/logo-filled.png";
import { useDispatch } from "react-redux";
import { setGameMode, hideLanding } from "../../store/reducers/ui";

export default function Landing() {
  const dispatch = useDispatch()

  const setMode = (mode) => {
    dispatch(setGameMode(mode));
    dispatch(hideLanding())
  }
  return (
    <div className="w-100 vh-100 b-blue flex-row justify-center landing">
      <div className="flex-column align-center">
        <div className="flex-column align-center landing-header">
          <img src={logo} alt="logo" className="logo" />
          <h1>Digits</h1>
        </div>
        <div className="flex-row landing-buttons">
          <button onClick={() => setMode('classic')}>Classic mode</button>
          <button onClick={() => setMode('timer')} className="flex-row">
            <TimerOutlinedIcon sx={{ marginRight: "5px" }} />
            Timer mode
            <span className="new-label">new</span>
          </button>
        </div>
      </div>
    </div>
  );
}
