import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const main = createSlice({
  name: "main",
  initialState: {
    game: {},
    currentTarget: null,
  },
  reducers: {
    setTargets(state, { payload }) {
      const newObj = {};
      payload.game.forEach((el) => (newObj[el.target] = { ...el }));
      state.currentTarget = payload.game[0].target;
      state.game = newObj;
    },
    updateNumbers(state, { payload }) {
      const { target, numbers } = payload;
      state.game[target].numbers = numbers;
      localStorage.setItem(
        "game",
        JSON.stringify({
          date: dayjs.utc().format("DD-MM-YYYY"),
          game: Object.values(state.game),
        })
      );
    },
    saveOperation(state, { payload }) {
      const { target, history } = payload;
      state.game[target].history = history;
      localStorage.setItem(
        "game",
        JSON.stringify({
          date: dayjs.utc().format("DD-MM-YYYY"),
          game: Object.values(state.game),
        })
      );
    },
    revealSolution(state, { payload }) {
      state.game[payload].reveal = true;
      localStorage.setItem(
        "game",
        JSON.stringify({
          date: dayjs.utc().format("DD-MM-YYYY"),
          game: Object.values(state.game),
        })
      );
    },
    saveScore(state, { payload }) {
      const { target, score } = payload;
      state.game[target].score = score;
      localStorage.setItem(
        "game",
        JSON.stringify({
          date: dayjs.utc().format("DD-MM-YYYY"),
          game: Object.values(state.game),
        })
      );
    },
    startGame(state){
      state.game[state.currentTarget].started = true
    },
    endGame(state, {payload}){
      state.game[state.currentTarget].endTime = payload
    },
    changeCurrentTarget(state, {payload}){
      state.currentTarget = payload.target
    }
  },
});

const { actions, reducer } = main;

export const {
  setTargets,
  saveOperation,
  updateNumbers,
  revealSolution,
  saveScore,
  startGame,
  endGame,
  changeCurrentTarget
} = actions;

export default reducer;
