function Back() {
  return (
    <svg
      width={30}
      viewBox="0 0 90 90"
      xmlSpace="preserve"
    >
      <path
        d="M45 16.4V8.6c0-2.5-3-4-5.1-2.5L17.5 20.6c-1.7 1.2-1.7 3.8 0 5l22.4 14.5c2.1 1.5 5.1 0 5.1-2.5v-8.8c12.8 0 23.2 10.2 23.2 22.6S57.8 74.1 45 74.1 21.8 63.9 21.8 51.5c0-3.4-2.9-6.2-6.4-6.2S9 48 9 51.5c0 19.3 16.1 35 36 35s36-15.7 36-35c0-19.4-16.2-35.1-36-35.1z"
        style={{ fill: "#fff"}}
        id="Symbol"
      />
    </svg>
  );
}

export default Back;
