import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./RevealModal.css";

export default function RevealModal({ isOpen, openModal, onReveal }) {

  const onClick = (option) => {
    if(option){
      onReveal()
    }
    openModal(false)
  }
  return (
    <Modal
      open={!!isOpen}
      onClose={() => {
        openModal(false);
      }}
      aria-labelledby="Rules"
    >
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon
            onClick={() => openModal(false)}
            className="close-button"
          />
          <div className="modal-content-container reveal-container">
            <h3>Are you sure?</h3>
            <p className="reveal-description">You can reveal a solution to this puzzle, but you will be unable to earn additional stars on the puzzle!</p>
            <button className="modal-button modal-button-black" onClick={() => onClick(false)}>
              Keep trying
            </button>
            <button className="modal-button" onClick={() => onClick(true)}>
              Reveal anyway
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
