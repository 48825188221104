function Subtract() {
  return (
    <svg
      width={30}
      viewBox="0 0 90 90"
    >
      <path
        d="M71.9 36H18.1c-5 0-9.1 4-9.1 9s4.1 9 9.1 9h53.8c5 0 9.1-4 9.1-9s-4.1-9-9.1-9z"
        fill="#fff"
        id="Symbol"
      />
    </svg>
  );
}

export default Subtract;
