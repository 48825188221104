import classNames from "classnames";
import Icon from "../icon";
import './Operations.css'

export default function Operations({ onClickOperation, selectedOperation }) {
  return (
    <div id="operations">
      <button
        onClick={() => onClickOperation("undo")}
        id="undo"
        aria-label="undo"
        className={classNames(selectedOperation === "undo" && "item-active")}
      >
        <Icon name="back" />
      </button>
      <button
        onClick={() => onClickOperation("add")}
        className={classNames(
          selectedOperation === "add" && "item-active",
          "operation"
        )}
        id="+"
        aria-label="add"
      >
        <Icon name="add" />
      </button>
      <button
        onClick={() => onClickOperation("subtract")}
        className={classNames(
          selectedOperation === "subtract" && "item-active",
          "operation"
        )}
        id="−"
        aria-label="subtract"
      >
        <Icon name="subtract" />
      </button>
      <button
        onClick={() => onClickOperation("multiply")}
        className={classNames(
          selectedOperation === "multiply" && "item-active",
          "operation"
        )}
        id="×"
        aria-label="multiply"
      >
        <Icon name="multiply" />
      </button>
      <button
        onClick={() => onClickOperation("divide")}
        className={classNames(
          selectedOperation === "divide" && "item-active",
          "operation"
        )}
        id="÷"
        aria-label="divide"
      >
        <Icon name="divide" />
      </button>
    </div>
  );
}
