import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./SubmitModal.css";
import { formatTime, getStars } from "../../util";
import { useDispatch, useSelector } from "react-redux";
import { ShareButton } from "../ShareButton";
import { changeCurrentTarget } from "../../store/reducers/main";

export default function SubmitModal({
  isOpen,
  openModal,
  resetStars,
  stars = 3,
  onClearSelections
}) {
  const dispatch = useDispatch()
  const { mode } = useSelector((state) => state.ui);
  const { game, currentTarget } = useSelector((state) => state.main);
  const { history } = game[currentTarget] || {};
  const result = history[history.length - 1]?.result
  const targets = Object.keys(game).map((el) => +el);
  const targetIndex = targets.indexOf(+currentTarget);

  let games = Object.values(game)
    .map((el) => {
      if (el.score || el.reveal) {
        return el;
      }
      return null;
    })
    .filter(Boolean);
  const completedAllGames = games.length === 5;

  const closeModal = () => {
    resetStars(null);
    openModal(false);
  };

  const nextPuzzle = () => {
    dispatch(changeCurrentTarget({target: targets[targetIndex + 1]}));
    onClearSelections()
    closeModal();
  };
  return (
    <Modal open={!!isOpen} onClose={closeModal} aria-labelledby="Your solution">
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon onClick={closeModal} className="close-button" />
          <div className="modal-content-container">
            <div className="modal-stars">
              {getStars(stars, 72, true, "#ffd105")}
            </div>
            <h3>Your solution</h3>
            <ul className="modal-history">
              { mode === 'timer' && (
                <p className="bold mb-10">Time: <span className="c-primary-blue">{formatTime(game[currentTarget].endTime)}</span></p>
              )}
              {history?.map((el) => (
                <li key={el.operation} className="modal-history-entry">
                  {el.operation}
                </li>
              ))}
            </ul>
            <ul className="details-history">
              <li className="details-entry">{`Target: ${currentTarget}`}</li>
              <li className="details-entry">{`You got: ${
                result || 0
              }`}</li>
              <li className="details-entry">{`Difference: ${
                result ? currentTarget - result : 0
              }`}</li>
            </ul>
            {!completedAllGames && (targetIndex + 1) !== targets.length && (
              <button
                className="modal-button modal-button-black"
                onClick={nextPuzzle}
              >
                Next puzzle
              </button>
            )}
            <button className="modal-button" onClick={closeModal}>
              Keep trying
            </button>
            <ShareButton />
          </div>
        </div>
      </div>
    </Modal>
  );
}
