import { useEffect, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "../App.css";
import {
  executeOperation,
  getStars,
  getSymbol,
  validateOperation,
} from "../util";
import { StatusBoard } from "../components/StatusBoard/Index";
import Operations from "../components/Operations";
import NumbersComponents from "../components/Numbers";
import dayjs from "dayjs";
import classNames from "classnames";
import SubmitModal from "../components/SubmitModal";
import {
  endGame,
  saveOperation,
  saveScore,
  updateNumbers,
} from "../store/reducers/main";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../components/Tabs";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { HistoryDrawer } from "../components/HistoryDrawer/Index";
import TabItems from "../components/TabItems";
import { Helmet } from "react-helmet";
import FAQS from "../components/FAQS";
import Ranking from "../components/Ranking";
import { ShareButton } from "../components/ShareButton";
import Landing from "../components/Landing";
import { changeCurrentTarget } from "../store/reducers/main";
import TimerSection from "../components/TimerSection";
import { stopTimer, toggleDrawer } from "../store/reducers/ui";
import PromoBanner from "../components/PromoBanner";

function Game() {
  const dispatch = useDispatch();
  const { mode, timer, navbar } = useSelector((state) => state.ui);
  const { game, currentTarget } = useSelector((state) => state.main);
  const { history, numbers, reveal } = game[currentTarget] || {};
  const [selectedOperation, setOperation] = useState();
  const [firstSelectedNumber, setFirstNumber] = useState();
  const [secondSelectedNumber, setSecondNumber] = useState();
  const [newNumberIndex, setNewNumberIndex] = useState();
  const [canSubmit, setCanSubmit] = useState();
  const [isRulesOpen, openRulesModal] = useState();
  const [isSubmitOpen, openSubmitModal] = useState(false);
  const [error, setError] = useState("");
  const latestNumbersSet = numbers ? [...numbers[numbers.length - 1]] : [[]];
  const shouldBlur = mode === "timer" && !game[currentTarget].started;
  const { width } = useWindowDimensions();
  const isTablet = width < 980;
  const isMobile = width < 520;
  const displayLanding = !localStorage.getItem('mode')

  useEffect(() => {
    if (firstSelectedNumber && !secondSelectedNumber) {
      // If we're on timer mode and the game ended, don't set stars
      if(mode === 'timer' && game[currentTarget].endTime) return

      const remaining = currentTarget - firstSelectedNumber.num;
      if (remaining > 0 && remaining < 11) {
        setCanSubmit(2);
      } else if (remaining > 10 && remaining < 26) {
        setCanSubmit(1);
      } else if (remaining === 0) {
        setCanSubmit(3);
      } else setCanSubmit(null);
      return;
    }

    if (firstSelectedNumber && secondSelectedNumber && selectedOperation) {
      runOperation();
    }
  }, [firstSelectedNumber, secondSelectedNumber, selectedOperation]);

  useEffect(() => {
    if (newNumberIndex) {
      const newNumberElement = document.getElementById(
        `number-${newNumberIndex}`
      );
      newNumberElement.classList.add("new-item-active");

      setTimeout(() => {
        newNumberElement.classList.remove("new-item-active");
      }, 400);
    }
  }, [newNumberIndex]);

  useEffect(() => {
    if (timer.timeLeft === 0) {
      endGameAndStopTimer();
      setCanSubmit(0);
      if (!reveal) {
        dispatch(saveScore({ target: currentTarget, score: canSubmit }));
      }
      openSubmitModal(true);
    }
  }, [timer.timeLeft]);

  // Clear all selected options
  const clearAll = () => {
    setOperation(null);
    setFirstNumber(null);
    setSecondNumber(null);
  };

  // Click on +, -, x or /
  const onClickOperation = (type) => {
    if (type === "undo" && numbers.length > 1) {
      setCanSubmit(null);
      setFirstNumber(null);
      dispatch(
        saveOperation({
          target: currentTarget,
          history: [...history].slice(0, -1),
        })
      );
      dispatch(
        updateNumbers({
          target: currentTarget,
          numbers: [...numbers].slice(0, -1),
        })
      );
      return;
    }
    if (type === selectedOperation) return setOperation(null);
    setOperation(type);
  };

  // Click on any number
  const onClickNumber = (num, id) => {
    if (id === firstSelectedNumber?.id) {
      setOperation(null);
      setFirstNumber(null);
      return;
    }
    if (!firstSelectedNumber) return setFirstNumber({ num, id });
    if (!secondSelectedNumber) {
      if (selectedOperation) {
        setSecondNumber({ num, id });
      } else setFirstNumber({ num, id });
    }
  };

  // Run math
  const runOperation = () => {
    const validOperation = validateOperation({
      selectedOperation,
      firstSelectedNumber: firstSelectedNumber.num,
      secondNumber: secondSelectedNumber.num,
      setSecondNumber,
      setOperation,
      showError,
    });

    if (!validOperation) return;

    setNewNumbers();
  };

  // Set state with new numbers
  const setNewNumbers = () => {
    const result = executeOperation(
      firstSelectedNumber.num,
      secondSelectedNumber.num,
      selectedOperation
    );

    const firstNumberIndex = +firstSelectedNumber.id.replace("number-", "");
    const secondNumberIndex = +secondSelectedNumber.id.replace("number-", "");

    latestNumbersSet[firstNumberIndex] = null;
    latestNumbersSet[secondNumberIndex] = result;

    // Animation
    const firstNumberElement = document.getElementById(firstSelectedNumber.id);
    const secondNumberElement = document.getElementById(
      secondSelectedNumber.id
    );

    firstNumberElement.style.top = secondNumberElement.offsetTop + "px";
    firstNumberElement.style.left = secondNumberElement.offsetLeft + "px";

    setTimeout(() => {
      setNewNumberIndex(secondNumberIndex);
      dispatch(
        updateNumbers({
          target: currentTarget,
          numbers: [...numbers, latestNumbersSet],
        })
      );
      const newHistory = [
        ...history,
        {
          id: `number-${history.length}`,
          operation: `${firstSelectedNumber?.num} ${getSymbol(
            selectedOperation
          )} ${secondSelectedNumber.num} = ${result}`,
          result,
        },
      ];
      dispatch(saveOperation({ target: currentTarget, history: newHistory }));

      clearAll();
    }, 200);

    if (result === currentTarget && game[currentTarget].endTime === null) {
      openSubmitModal(true);
      setCanSubmit(3);
      if (mode === "timer") {
        endGameAndStopTimer();
      }
      if (!reveal) {
        dispatch(saveScore({ target: currentTarget, score: 3 }));
      }
    }
  };

  const onSubmit = () => {
    openSubmitModal(true);
    if (mode === "timer") {
      endGameAndStopTimer();
    }
    if (!reveal) {
      dispatch(saveScore({ target: currentTarget, score: canSubmit }));
    }
  };

  const showError = (str) => {
    setError(str);
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const onClickTab = (currentTarget) => {
    setError("");
    clearAll();
    dispatch(changeCurrentTarget({ target: currentTarget }));
  };

  const endGameAndStopTimer = () => {
    dispatch(stopTimer());
    dispatch(endGame(timer.timeLeft));
  };

  if(!game || !currentTarget) return

  if (displayLanding) return <Landing />;

  return (
    <div className="game">
      <Helmet
        title="Digits NYT Puzzle"
        description="A daily maths game for those who loved playing The New York Times puzzle Digits."
      />
      {/* <PromoBanner /> */}
      <div className="game-header justify-between flex-row">
        { !isMobile && (
          <div className="game-header-container flex-column align-start">
          <div className="flex-row header-info">
            <h1 className="mr-10">Digits NYT Puzzle</h1>
            <p className="date">{dayjs(new Date()).format("dddd, MMMM DD")}</p>
          </div>
          <p className="game-header-container-description">
            This is for all those who loved playing The New York Times daily
            numbers puzzle Digits 🤓
          </p>
        </div>
        )}
        {isMobile && (
          <div className="flex-row">
            <h1 className="mr-10">Digits NYT</h1>
            <TabItems openModal={openRulesModal} isOpen={isRulesOpen} />
          </div>
        
      )}
        <MenuRoundedIcon
          className="pointer"
          style={{ fontSize: 30, width: 30 }}
          onClick={() => dispatch(toggleDrawer(!navbar.isOpen))}
        />
      </div>
      <Tabs onClickTab={onClickTab} game={game} />
      {isTablet && <HistoryDrawer history={history} />}
      <div className="game-wrapper">
        <div className="game-container">
          {mode === "timer" && <TimerSection />}
          <p className={classNames("f14", error && "error")}>
            {error || `Use any combination of numbers to reach the target:`}
          </p>
          <h2 className={classNames(shouldBlur && "blur")}>{currentTarget}</h2>
          <NumbersComponents
            shouldBlur={shouldBlur}
            numbers={latestNumbersSet}
            onClickNumber={onClickNumber}
            firstSelectedNumber={firstSelectedNumber}
            secondSelectedNumber={secondSelectedNumber}
          />
          <Operations
            onClickOperation={onClickOperation}
            selectedOperation={selectedOperation}
          />
          <div className="flex-row">
            {game[currentTarget].endTime === null && (
              <button
                disabled={!canSubmit}
                onClick={onSubmit}
                className={classNames(
                  "submit-button",
                  canSubmit && "submit-button-active",
                  "mr-10"
                )}
              >
                Submit
                {getStars(canSubmit)}
              </button>
            )}
            <ShareButton />
          </div>
        </div>
        {!isTablet && (
          <div className="flex-column w-50">
            <Ranking />
            <StatusBoard history={history} />
          </div>
        )}
        <SubmitModal
          isOpen={isSubmitOpen}
          openModal={openSubmitModal}
          resetStars={setCanSubmit}
          stars={canSubmit}
          onClearSelections={clearAll}
        />
      </div>
      <FAQS />
    </div>
  );
}

export default Game;
