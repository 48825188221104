import { useState } from "react";
import classNames from "classnames";
import "./Tabs.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import TabItems from "../TabItems";
import { getStars } from "../../util";
import { useSelector } from "react-redux";

export default function Tabs({ onClickTab, game }) {
  const {mode, timer } = useSelector(state => state.ui)
  const {currentTarget} = useSelector(state => state.main)
  const [isOpen, openModal] = useState();
  const { width } = useWindowDimensions();
  const isMobile = width < 520;
  const targets = Object.keys(game);
  const isTimerModer = mode === 'timer'

  return (
    <div className="tabs">
      <div className="flex-row justify-between tabs-container">
        <div className="flex-row">
          {targets.map((target, i) => (
            <div
              key={target}
              onClick={() => onClickTab(target)}
              className={classNames(
                "tab",
                +target === +currentTarget && "tab-active",
                isTimerModer && timer.timerActive && 'events-none'
              )}
            >
              <p className={classNames(isTimerModer && !game[target].started && 'blur')}>{target}</p>
              {game[target].score && (
                <div className="flex-row stars">
                  {getStars(game[target].score, null, true, "#fde456")}
                </div>
              )}
            </div>
          ))}
        </div>
        {!isMobile && (
          <TabItems
            openModal={openModal}
            isOpen={isOpen}
          />
        )}
      </div>
    </div>
  );
}
