import { useSelector } from "react-redux";
import "./HistoryDrawer.css";
import { useState } from "react";
import classNames from "classnames";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { calculateSteps } from "../../util";

export function HistoryDrawer({ history = [] }) {
  const { currentTarget, game } =
  useSelector((state) => state.main)
  const { expression, reveal } = game[currentTarget] || {};
  const [isCollapsed, setCollapsed] = useState(true);

  return (
    <div className="history-drawer" onClick={() => setCollapsed(!isCollapsed)}>
      <div
        id="game-status-mobile"
        className={classNames(isCollapsed && "collapsed")}
      >
        <KeyboardArrowDownRoundedIcon
          style={{ position: "absolute", right: 11, top: 9 }}
        />
        {!isCollapsed && (
          <div>
            <div id="history-container-desktop" className="justify-between">
              <div className="flex-column history-container-content">
                <div id="history-header-desktop" className="history-header">
                  Your operations
                </div>
                <ul className="history-desktop">
                  {history.map((el) => (
                    <li key={el.operation} className="history-entry">
                      {el.operation}
                    </li>
                  ))}
                </ul>
              </div>
              {reveal && (
                <div className="flex-column history-container-content solution-container">
                  <div id="history-header-desktop" className="history-header">
                    Our solution
                  </div>
                  <ul className="history-desktop">
                    {calculateSteps(expression).map((el) => (
                      <li key={el} className="history-entry">
                        {el}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
