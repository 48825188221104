import './drawer.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import NavbarDrawer from '../NavbarDrawer';
import { toggleDrawer } from '../../store/reducers/ui';

export default function DrawerComponent() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.ui.navbar);

  const onCloseDrawer = () => {
    dispatch(toggleDrawer(false));
  };

  const Element = NavbarDrawer;

  return (
    <Drawer className="" anchor="right" open={isOpen} onClose={onCloseDrawer}>
      <button
        name="close"
        onClick={onCloseDrawer}
        className="mb-20 close-drawer-icon no-style-btn pos-absolute pointer"
      >
        <ClearRoundedIcon />
      </button>
      {Element ? <Element /> : null}
    </Drawer>
  );
}
