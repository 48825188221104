import classNames from "classnames";
import './Numbers.css'
import { useDispatch } from "react-redux";
import { startGame } from "../../store/reducers/main";
import { startTimer } from "../../store/reducers/ui";

export default function Numbers({ numbers, onClickNumber, firstSelectedNumber, secondSelectedNumber, shouldBlur }) {
  const dispatch = useDispatch()
  
  const onClickStart = () => {
    dispatch(startGame())
    dispatch(startTimer())
  }
  return (
   <>
   {shouldBlur && <button onClick={onClickStart} className="start-button">Start</button> }
    <div className={classNames("numbers", shouldBlur && 'blur')}>
    {numbers.map((num, index) => (
      <button
        key={`${num}-${index}`}
        onClick={() => onClickNumber(num, `number-${index}`)}
        className={classNames(
          "number",
          num
            ? (`number-${index}` === firstSelectedNumber?.id ||
                `number-${index}` === secondSelectedNumber?.id) &&
                "item-active"
            : "item-empty"
        )}
        id={`number-${index}`}
      >
        {num}
      </button>
    ))}
  </div>
   </>
  );
}
