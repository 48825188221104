import { useSelector } from "react-redux";
import "./ShareButton.css";
import { Snackbar } from "@mui/material";
import { useState } from "react";
import { formatTime } from "../../util";

export const ShareButton = () => {
  const { game } = useSelector((state) => state.main);
  const { mode } = useSelector((state) => state.ui);
  const [isOpen, openSnackbar] = useState(false)

  let games = Object.values(game)
    .map((el) => {
      if (el.score || el.reveal) {
        return el;
      }
      return null;
    })
    .filter(Boolean);

  const scores = games.filter((el) => el.score).map((el) => el.score);
  let score = scores.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const canShare = games.length === 5;

  function copyText() {
    navigator.clipboard.writeText(
      `  Digits (${score}/15 ⭐)
  ${games[0].target} ${games[0].score ? new Array(games[0].score).fill('⭐').map(el => el).join('') : '⤫'}${mode === 'timer' ? ` - ${formatTime(games[0].endTime)}` : ''}
  ${games[1].target} ${games[1].score ? new Array(games[1].score).fill('⭐').map(el => el).join('') : '⤫'}${mode === 'timer' ? ` - ${formatTime(games[1].endTime)}` : ''}
  ${games[2].target} ${games[2].score ? new Array(games[2].score).fill('⭐').map(el => el).join('') : '⤫'}${mode === 'timer' ? ` - ${formatTime(games[2].endTime)}` : ''}
  ${games[3].target} ${games[3].score ? new Array(games[3].score).fill('⭐').map(el => el).join('') : '⤫'}${mode === 'timer' ? ` - ${formatTime(games[3].endTime)}` : ''}
  ${games[4].target} ${games[4].score ? new Array(games[4].score).fill('⭐').map(el => el).join('') : '⤫'}${mode === 'timer' ? ` - ${formatTime(games[4].endTime)}` : ''}
  playdigitsnyt.com
    `);
    openSnackbar(true)
  }

  if (!canShare) return null;

  return (
    <>
      <button onClick={copyText} className="submit-button share-button undefined">
        Share
      </button>
      <Snackbar
        open={!!isOpen}
        autoHideDuration={2000}
        onClose={openSnackbar}
        message="Copied results to clipboard"
      />
    </>
  );
};
