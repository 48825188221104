function Multiply() {
  return (
    <svg
      width={30}
      id="Layer_1"
      viewBox="0 0 90 90"
    >
      <path
        d="m57.2 45 12.2-12.2c3.4-3.4 3.4-8.9.1-12.3s-8.9-3.4-12.3 0L45 32.8 32.8 20.6c-3.4-3.4-8.9-3.4-12.3 0s-3.4 8.9 0 12.3L32.8 45 20.6 57.2c-3.4 3.4-3.4 8.9 0 12.3s8.9 3.4 12.3-.1L45 57.2l12.2 12.2c3.4 3.4 8.9 3.4 12.3.1 3.4-3.4 3.4-8.9-.1-12.3L57.2 45z"
        fill="#fff"
        id="Symbol"
      />
    </svg>
  );
}

export default Multiply;
