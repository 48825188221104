import { useSelector } from "react-redux";
import Timer from "./Timer";
import "./TimerSection.css";

export default function TimerSection() {
  const { game, currentTarget } = useSelector((state) => state.main);
  const isGameEnded = game[currentTarget].endTime;

  return (
    <div className="flex-row timer-section">
      <p className="bold mr-5 mt-0 mb-0">Time left:</p>
      <p className="bold c-primary-blue mt-0 mb-0">
        {typeof isGameEnded === 'number' ? `00:${isGameEnded < 10 ? `0${isGameEnded}` : isGameEnded}` : (game[currentTarget]?.started ? <Timer /> : "01:00")}
      </p>
    </div>
  );
}
