import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";

export const validateOperation = ({
  selectedOperation,
  firstSelectedNumber,
  secondNumber,
  setSecondNumber,
  setOperation,
  showError
}) => {
  if (selectedOperation === "subtract") {
    if (firstSelectedNumber < secondNumber) {
      setSecondNumber(null);
      setOperation(null);
      showError("Subtraction can't result in negative number!");
      return false;
    }
  }
  if (selectedOperation === "divide") {
    if (firstSelectedNumber < secondNumber) {
      setSecondNumber(null);
      setOperation(null);
      showError("Bad division!");
      return false;
    } 
    if(firstSelectedNumber % secondNumber !== 0){
      setSecondNumber(null);
      setOperation(null);
      showError("Bad division!");
      return false;
    }
  }
  return true;
};

export const executeOperation = (firstNumber, secondNumber, operation) => {
  if (operation === "add") {
    return firstNumber + secondNumber;
  }
  if (operation === "multiply") {
    return firstNumber * secondNumber;
  }
  if (operation === "divide") {
    return firstNumber / secondNumber;
  }
  if (operation === "subtract") {
    return firstNumber - secondNumber;
  }
};


export const getSymbol = (str) => {
  if (str === "add") {
    return "+";
  }
  if (str === "multiply") {
    return "x";
  }
  if (str === "divide") {
    return "/";
  }
  if (str === "subtract") {
    return "-";
  }
};

export const getStars = (stars, fontSize = 20, filled, fill = 'black') => {
  if (!stars) return "";

  const Component = filled ? StarRateRoundedIcon : StarOutlineRoundedIcon;
  return [...new Array(stars)].map((star, i) => (
    <Component
      key={i}
      style={{ 
        marginLeft: i !== 0 ? -5 : 0,
        fontSize,
        fill
      }}
    />
  ));
};


export function calculateSteps(expression) {
  const parts = expression.split(' ');
  const results = [];
  let currentResult = Number(parts[0]);

  for (let i = 1; i < parts.length; i += 2) {
    const operator = parts[i];
    const nextNumber = Number(parts[i + 1]);
    const previousResult = currentResult;

    switch (operator) {
      case '+':
        currentResult += nextNumber;
        break;
      case '-':
        currentResult -= nextNumber;
        break;
      case '*':
        currentResult *= nextNumber;
        break;
      case '/':
        currentResult /= nextNumber;
        break;
      default:
        throw new Error(`Unknown operator: ${operator}`);
    }

    results.push(`${previousResult} ${operator} ${nextNumber} = ${currentResult}`);
  }

  return results;
}

export const formatTime = num => {
  if(num < 10){
    return `00:0${num}`
  } else return `00:${num}`
}

