import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTimeLeft } from "../../store/reducers/ui";

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

function Timer() {
  const dispatch = useDispatch()
  const { timer } = useSelector(state => state.ui)

  useEffect(() => {
    if (!timer.timeLeft || !timer.timerActive || timer.timeLeft === 0) return;

    // Save intervalId to clear the interval when the component unmounts
    const intervalId = setInterval(() => {
      dispatch(updateTimeLeft(timer.timeLeft - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer.timeLeft]);

  return <p className="mt-0 mb-0">{formatTime(timer.timeLeft)}</p>;
}

export default Timer;
