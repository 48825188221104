/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-pascal-case */
import classNames from 'classnames';
import iconComponents from './Icons';
import './Icon.css';

export function Icon({
  name,
  size = 14,
  className,
  alt,
  onClick,
  style,
  ...props
}) {
  if (!name) return null;

  const SvgComponent = iconComponents[name];

  return (
    <div
      className={classNames(
        'icon',
        `icon-${name}`,
        className,
        onClick && 'pointer',
      )}
      onClick={onClick}
    >
      {alt && <span className="accessible-hide">{alt}</span>}
      {SvgComponent && (
        <SvgComponent.default
          style={style}
          {...props}
        />
      )}
      {!SvgComponent && (
        <span style={{ display: 'inline-block', width: size, height: size }} />
      )}
    </div>
  );
}

export default Icon;
