import './FAQS.css'
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function FAQS(){
  return(
    <div className="faqs" id="faqs">
    <h3>FAQs</h3>
    <h2>
      Is Digits NYT the daily number puzzle game from The New York Times?
    </h2>
    <p>
      No. The New York Times cancelled Digits on August 8, 2023. We’re just
      two fans who wanted to keep playing and thought we’d share the math
      game with other fans.
    </p>
    <h2>How to play Digits NYT?</h2>
    <p>
      Try to get to the target number by adding, subtracting, multiplying or
      dividing the six numbers provided (you don't have to use them all). Click on the <HelpOutlineIcon style={{ width: 20,
    marginBottom: -5, display: 'initial' }}/> at the top for more info.
    </p>
    <h2>When is a new Digits NYT puzzle available?</h2>
    <p>You can play a new numbers puzzle every day at midnight (UTC).</p>
  </div>
  )
}