function Add() {
  return (
    <svg
      width={30}
      viewBox="0 0 90 90"
    >
      <path
        d="M71.9 36H54V18.1c0-5-4-9.1-9-9.1s-9 4.1-9 9.1V36H18.1c-5 0-9.1 4-9.1 9s4.1 9 9.1 9H36v17.9c0 5 4 9.1 9 9.1s9-4.1 9-9.1V54h17.9c5 0 9.1-4 9.1-9s-4.1-9-9.1-9z"
        fill="#fff"
        id="Symbol"
      />
    </svg>
  );
}

export default Add;
